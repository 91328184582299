import {
  Box,
  Flex,
  Text,
  Separator,
  Switch,
  Button,
  Radio,
} from "@radix-ui/themes";
import React, { useEffect } from "react";
import { styled } from "@stitches/react";
import colors from "./colors";
import upIcon from "../icons/up.svg";
import downIcon from "../icons/bottom.svg";
import rightIcon from "../icons/right.svg";
import leftIcon from "../icons/left.svg";
import dotIcon from "../icons/dot.svg";
import zoomInIcon from "../icons/zoom-in.svg";
import zoomOutIcon from "../icons/zoom-out.svg";
import separatorIcon from "../icons/separator.svg";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import api from "../api/api";
import RtspLive from "./RtspLive";

const Container = styled(Flex, {
  justifyContent: "flex-start",
  padding: "20px 10px 20px 10px",
});

const Card = styled(Box, {
  borderRadius: 10,
  border: "1px solid #D9D9D9",
  background: "#FCFDFF",
  boxShadow: "0px 2px 6px 0px rgba(183, 181, 188, 0.12)",
  Width: "240px",
});

const Controller = styled(Flex, {
  width: "140px",
  height: "140px",
  borderRadius: "50%",
  border: `4px solid ${colors.base}`,
  background: colors.deepGray,
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
});

const InnerCircle = styled(Flex, {
  position: "relative",
  width: "120px",
  height: "120px",
  borderRadius: "50%",
  background: "White",
});

const Arrow = styled(Box, {
  position: "absolute",
  // Variants for different arrow positions
  variants: {
    position: {
      top: {
        top: "10px",
        left: "50%",
        transform: "translateX(-50%)", // Center horizontally
      },
      bottom: {
        bottom: "5px",
        left: "50%",
        transform: "translateX(-50%)", // Center horizontally
      },
      left: {
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)", // Center vertically
      },
      right: {
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)", // Center vertically
      },
    },
  },
});

const MoreButton = styled(Button, {
  height: "15px !important",
  background: `${colors.secondary} !important`,
  borderRadius: "10px",
});

const FlyingEye = () => {
  const [cameras, setCameras] = React.useState([]);
  const fetchCameras = async () => {
    const response = await api.get("/camera");
    setCameras(response.data);
  };

  const [startingStream, setStartingStream] = React.useState(false);

  useEffect(() => {
    fetchCameras();
  }, []);

  const handleCameraSwitch = (camera) => {
    console.log("sss");
    const updatedCameras = cameras.map((c) => {
      if (c.id === camera.id) {
        return { ...c, selected: !c.selected };
      }
      return c;
    });
    setCameras(updatedCameras);
  };

  const startSteam = async (cameraId) => {
    setStartingStream(true);
    try {
      const response = await api.get(`/camera/${cameraId}/start`);
      const updatedCameras = cameras.map((c) => {
        if (c.id === cameraId) {
          return response.data;
        }
        return c;
      });
      setCameras(updatedCameras);
      setStartingStream(false);
    } catch (error) {
      console.error("Error fetching Camera:", error);
      setStartingStream(false);
    }
  };

  const selectedCameras = cameras.filter(
    (camera, index) => index === 0 || camera.selected
  );

  return (
    <Container gapX="8px">
      <Box>
        <Card>
          <Flex justify="center" align="center" p="10px" width="240px">
            <Text size="3" color="violet" weight="bold">
              Flying Eye
            </Text>
          </Flex>
        </Card>
        <Card mt="20px">
          <Flex
            p="15px"
            width="240px"
            justify="start"
            align="center"
            gapX="30px"
          >
            <Controller justify="center" align="center">
              <InnerCircle justify="center" align="center">
                <img src={dotIcon} alt="dot image icon" />
                <Arrow position="top">
                  <img src={upIcon} alt="up controller icon" />
                </Arrow>
                <Arrow position="bottom">
                  <img src={downIcon} alt="down controller icon" />
                </Arrow>
                <Arrow position="right">
                  <img src={rightIcon} alt="right controller icon" />
                </Arrow>
                <Arrow position="left">
                  <img src={leftIcon} alt="left controller icon" />
                </Arrow>
              </InnerCircle>
            </Controller>
            <Box>
              <Flex direction="column" gapY="10px">
                <img src={zoomOutIcon} alt="zoom out icon" />
                <img src={separatorIcon} alt="separator in icon" />
                <img src={zoomInIcon} alt="zoom in icon" />
              </Flex>
            </Box>
          </Flex>
        </Card>

        <Card mt="20px">
          <Flex
            direction="column"
            style={{
              borderBottom: "1px solid #D9D9D9",
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
            }}
            p="24px 16px"
          >
            <Text size="3" weight="bold">
              Camera selection
            </Text>
            <Text size="1" mt="1">
              Select camera(s) to display
            </Text>
          </Flex>
          <Flex p="24px 16px" direction="column" gapY="16px">
            {cameras.map((camera, index) => (
              <Box key={camera.id}>
                <Flex align="center">
                  <Text size="2">{camera.name}</Text>
                  <Switch
                    size="1"
                    defaultChecked={index === 0}
                    ml="auto"
                    onCheckedChange={() => handleCameraSwitch(camera)}
                    onChange={() => handleCameraSwitch(camera)}
                  />
                </Flex>
                {index < cameras.length - 1 && <Separator size="4" />}
              </Box>
            ))}
            <MoreButton>
              <MdOutlineKeyboardDoubleArrowDown color={colors.active} />
            </MoreButton>
          </Flex>
        </Card>

        <Card p="20px" mt="20px">
          <Text size="3" weight="bold">
            Camera Option
          </Text>
          <Flex align="center" mt="25px">
            <Text size="2">Mainstream</Text>
            <Radio name="mainstream" value="mainstream" ml="auto" />
          </Flex>
          <Flex align="center" mt="4px">
            <Text size="2">Substream</Text>
            <Radio name="mainstream" value="mainstream" checked ml="auto" />
          </Flex>
        </Card>
      </Box>
      <Card style={{ flexGrow: 1 }}>
        <Flex direction="column" gapY="20px" style={{ padding: "20px 100px" }}>
          {selectedCameras.map((camera) => (
            <RtspLive
              camera={camera}
              onStream={startSteam}
              key={camera.id}
              loading={startingStream}
            />
          ))}
        </Flex>
      </Card>
    </Container>
  );
};

export default FlyingEye;
