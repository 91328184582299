import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Link,
  TextField,
  Select,
  Button,
} from "@radix-ui/themes";
import Logo from "./atom-logo.svg";
import { LuUser2, LuKeyRound } from "react-icons/lu";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api/api";
import { userRoles } from "./constants";

const Signup = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const email = searchParams.get("email");

  const notifyError = (msg) => toast.error(msg);
  const notifySuccess = (msg) => toast.success(msg);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRole = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      role: value,
    }));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const payload = {
      name: `${formData.firstName} ${formData.lastName}`,
      password: formData.password,
      role: formData.role,
      email,
    };
    if (formData.password !== formData.confirmPassword) {
      notifyError("Passwords do not match");
      return;
    }
    try {
      const response = await api.post("/auth/signup", payload);
      localStorage.setItem("accessToken", response.data?.accessToken);
      localStorage.setItem("accessLevel", response.data?.user?.access_level);
      notifySuccess("Signed up successfully");
      setSuccess(true);
    } catch (err) {
      notifyError(err.response.data?.error ?? "Signup unsuccessful");
    }
  };

  const passwordsMatch = formData.password === formData.confirmPassword;

  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Box
        style={{
          background: "#F8F8F8",
          border: "1px solid #A5A8A6",
          borderRadius: 10,
          padding: 60,
          width: 800,
          height: 670,
        }}
      >
        {success ? (
          <Box>
            <Flex justify="end">
              <Flex direction="column" align="center">
                <Box width="100px" height="100px">
                  <img
                    src={Logo}
                    alt="Atom logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Text size="6" mt="3" weight="bold">
                  ATOM
                </Text>
              </Flex>
            </Flex>
            <Flex direction="column" justify="center" align="center" mt="70px">
              <Text size="5" weight="bold" align="center">
                All set !
              </Text>
              <Text size="3" align="center" mt="10px">
                Your eyes on the site are ready to use !
              </Text>
              <Button
                radius="full"
                size="4"
                style={{ width: "200px" }}
                mt="60px"
                onClick={() => navigate("/dashboard")}
              >
                Go to platform
              </Button>
            </Flex>
          </Box>
        ) : (
          <form onSubmit={handleSignup}>
            <Flex justify="start" align="center" gapX="280px">
              <Flex direction="column">
                <Text size="7" mb="3" weight="bold">
                  Get started now
                </Text>
                <Text size="3">
                  Already have an account?{" "}
                  <Link href="/login" underline="always">
                    Sign in
                  </Link>
                </Text>
              </Flex>
              <Flex direction="column" align="center">
                <Box width="100px" height="100px">
                  <img
                    src={Logo}
                    alt="Atom logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Text size="6" mt="3" weight="bold">
                  ATOM
                </Text>
              </Flex>
            </Flex>
            <Flex justify="start" gapX="30px" mt="40px">
              <Box minWidth="320px">
                <TextField.Root
                  placeholder="First name"
                  size="3"
                  name="firstName"
                  value={formData.firstName}
                  required
                  onChange={handleChange}
                >
                  <TextField.Slot side="right">
                    <LuUser2 height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
              </Box>
              <Box minWidth="320px">
                <TextField.Root
                  type="password"
                  placeholder="Choose a password"
                  size="3"
                  name="password"
                  value={formData.password}
                  required
                  onChange={handleChange}
                >
                  <TextField.Slot side="right">
                    <LuKeyRound height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
              </Box>
            </Flex>
            <Flex justify="start" gapX="30px" mt="20px">
              <Box minWidth="320px">
                <TextField.Root
                  placeholder="Last name"
                  size="3"
                  name="lastName"
                  value={formData.lastName}
                  required
                  onChange={handleChange}
                >
                  <TextField.Slot side="right">
                    <LuUser2 height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
              </Box>
              <Box minWidth="320px">
                <TextField.Root
                  type="password"
                  placeholder="Confirm your password"
                  size="3"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                >
                  <TextField.Slot side="right">
                    <LuKeyRound height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
                {formData.confirmPassword && !passwordsMatch && (
                  <Text size="2" color="tomato">
                    Passwords do not match
                  </Text>
                )}
              </Box>
            </Flex>
            <Flex justify="start" gapX="30px" mt="20px">
              <Box minWidth="320px">
                <Select.Root
                  size="3"
                  name="role"
                  value={formData.role}
                  onValueChange={handleRole}
                >
                  <Select.Trigger
                    style={{ width: "320px" }}
                    placeholder="Your role"
                    required
                  />
                  <Select.Content style={{ width: "320px" }}>
                    {userRoles.map((role) => (
                      <Select.Item value={role.id} key={role.id}>
                        {role.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </Box>
            </Flex>
            <Flex justify="center" mt="40px">
              <Button
                radius="full"
                size="4"
                style={{ width: "200px" }}
                type="submit"
              >
                Sign up
              </Button>
            </Flex>
            <Flex justify="center" mt="50px">
              <Link href="#" underline="always">
                Privacy policy and terms of service agreements
              </Link>
            </Flex>
          </form>
        )}
      </Box>
    </Flex>
  );
};

export default Signup;
