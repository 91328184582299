import React, { useState, useEffect, useRef } from "react";
import { styled } from "@stitches/react";
import { Box, Button } from "@radix-ui/themes";
import { getFormattedUTCDate } from "../utils";

const Container = styled(Box, {
  width: "100%",
  maxWidth: "650px",
  height: "350px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #ccc",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "#f0f0f0",
  position: "relative",
});

const ImageElement = styled("img", {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  display: (props) => (props.isStreaming ? "block" : "none"),
  position: "relative",
});

const CameraName = styled(Box, {
  position: "absolute",
  bottom: "10px",
  left: "10px",
  color: "#fff",
  padding: "5px",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
});

const Timer = styled(Box, {
  position: "absolute",
  top: "10px",
  left: "10px",
  color: "#fff",
  padding: "5px",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
});

const TitleOverlay = styled("div", {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: "16px",
  color: "#555",
  display: (props) => (props.isStreaming ? "none" : "flex"),
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const StartButton = styled(Button, {
  marginTop: "10px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  padding: "8px 12px",
  borderRadius: "4px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#0056b3",
  },
});

const ErrorMessage = styled(Box, {
  color: "red",
  fontSize: "14px",
  marginTop: "10px",
});

const RtspLive = ({ camera, onStream, loading }) => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const imgRef = useRef(null);

  useEffect(() => {
    // Check every 5 seconds if the image is still updating
    const checkInterval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastUpdated > 5000 && imgRef.current) {
        // 5-second threshold
        // If the image has not updated in the last 5 seconds, refetch
        imgRef.current.src = `${
          camera.camerasApi?.getPlayback
        }?timestamp=${new Date().getTime()}`;
      }
      setIsStreaming(true);
    }, 5000);

    return () => clearInterval(checkInterval); // Cleanup the interval on unmount
  }, [lastUpdated, camera.camerasApi?.getPlayback]);

  const handleStream = () => {
    onStream(camera.id);
  };

  const handleImageLoad = () => {
    setLastUpdated(Date.now());
  };

  return (
    <Container>
      {camera.camerasApi?.getPlayback && (
        <Box>
          <ImageElement
            security="restricted"
            onLoad={handleImageLoad}
            src={camera.camerasApi?.getPlayback}
            isStreaming={isStreaming}
          />
          <Timer>{getFormattedUTCDate()}</Timer>
          <CameraName>{camera.name}</CameraName>
        </Box>
      )}
      {!camera.camerasApi?.getPlayback && (
        <TitleOverlay>
          <div>No Stream Available</div>
          <StartButton onClick={handleStream} disabled={loading}>
            Start stream
          </StartButton>
        </TitleOverlay>
      )}
    </Container>
  );
};

export default RtspLive;
