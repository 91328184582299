export function omit(obj, keyToOmit) {
  const { [keyToOmit]: _, ...rest } = obj;
  return rest;
}

export function getFormattedUTCDate() {
  const currentDate = new Date();

  // Get individual components
  const day = String(currentDate.getUTCDate()).padStart(2, "0"); // Day with leading zero
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0"); // Month with leading zero
  const year = currentDate.getUTCFullYear();

  const hours = String(currentDate.getUTCHours()).padStart(2, "0");
  const minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");

  // Get day name (e.g., Mon, Tue, etc.)
  const dayName = currentDate.toLocaleString("en-GB", {
    weekday: "short",
    timeZone: "UTC",
  });

  // Combine all parts into the desired format
  const formattedDate = `${day}-${month}-${year} ${dayName} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}
