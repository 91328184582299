import { Box, Flex, Text } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import colors from "./colors";

const Container = styled(Box, {
  background: colors.background,
  padding: "20px 10px 20px 10px",
});

const PDFContainer = styled(Flex, {
  padding: "60px 10px 60px 10px",
  width: "820px",
  borderRadius: "5px",
  background: "#FFF",
  boxShadow: "0px 4px 10px 0px #D9D9D9",
});

const ControlContainer = styled(Box, {
  padding: "20px 10px 20px 10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexGrow: 1,
  borderRadius: "10px",
  background: "#F5F6FA",
  boxShadow: "0px 4px 6px 0px #D9D9D9",
});

const pdfFileUrl = "https://pdfobject.com/pdf/sample.pdf";

const SightInsights = () => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  return (
    <Container>
      <Text color="violet" size="4" weight="bold">
        Site Insights
      </Text>
      <Flex mt="15px" gapX="25px" height="100vh">
        <PDFContainer direction="column" align="center" justify="center">
          <Text color="violet" size="3" weight="bold" align="center" mb="10px">
            Document Title: Report October0624
          </Text>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={pdfFileUrl} plugins={[zoomPluginInstance]} />
          </Worker>
          <Flex gapX="10px" mt="10px">
            <ZoomOutButton />
            <ZoomInButton />
          </Flex>
        </PDFContainer>
        <ControlContainer></ControlContainer>
      </Flex>
    </Container>
  );
};

export default SightInsights;
