import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import FlyingEye from "./components/FlyingEye";
import ForgetPassword from "./components/ForgetPassword";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./components/ResetPassword";
import Signup from "./components/Signup";
import SightInsights from "./components/SiteInsights";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {/* Protect dashboard route */}
        <Route element={<PrivateRoute />}>
          <Route path="dashboard" element={<Dashboard />}>
            <Route path="camera" element={<FlyingEye />} />
            <Route path="insights" element={<SightInsights />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
