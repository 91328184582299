import React, { useState } from "react";
import { Box, Text, Link, Flex, Select, Avatar } from "@radix-ui/themes";
import colors from "./colors";
import logo from "./atom-logo.svg";
import { styled } from "@stitches/react";
import { GoGear } from "react-icons/go";
import { sampleImageUrl } from "../utils/constants";
import dashboardIcon from "../icons/dashboard.svg";
import cameraIcon from "../icons/camera.svg";
import insightIcon from "../icons/insights.svg";
import eyalIcon from "../icons/eyal.svg";
import trackingIcon from "../icons/tracking.svg";
import documentationIcon from "../icons/documentation.svg";
import {
  MdKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Outlet, useNavigate } from "react-router";

const Nav = styled(Box, {
  height: "80px",
  width: "100%",
  borderBottomRightRadius: "20px",
  borderBottomLeftRadius: "20px",
  background: colors.base,
  padding: 20,
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000,
});

const DashboardContent = styled(Box, {
  paddingTop: "80px",
  // background: colors.background,
  height: "100vh",
});
const SideMenu = styled(Box, {
  height: "100vh",
  width: "240px",
  paddingTop: "80px",
  paddingBottom: 20,
  borderRadius: "0px 0px 15px 15px",
  background: colors.secondary,
  boxShadow: "0px 2px 6px 0px rgba(183, 181, 188, 0.12)",
  border: "1px solid var(#E6EBF9, rgba(101, 99, 200, 0.25))",
  transition: "width 0.3s ease",
  top: 0,
  left: 0,
  position: "fixed",
  marginTop: "50px",
});

const StyledMenuItem = styled(Flex, {
  justifyContent: "start",
  alignItems: "center",
  padding: 20,
  columnGap: 20,
  cursor: "pointer",
  position: "relative",
  transition: "background-color 0.3s ease",
  backgroundColor: "transparent",

  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    width: 0,
    height: "100%",
    backgroundColor: colors.active,
    transition: "width 0.3s ease",
  },

  // Conditional styling for active state
  variants: {
    active: {
      true: {
        backgroundColor: "white",
        "&::before": {
          width: "5px",
        },
      },
      false: {
        backgroundColor: "transparent",
        "&::before": {
          width: "0",
        },
      },
    },
  },
});

const MenuControl = styled(Flex, {
  justifyContent: "start",
  alignItems: "center",
  columnGap: 20,
  cursor: "pointer",
  paddingLeft: 20,
  paddingRight: 20,
  marginTop: 50,
});

const MenuItem = ({
  id,
  icon,
  alt,
  collapsed,
  title,
  onClick,
  active,
  path,
}) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(path);
    onClick(id);
  };
  return (
    <StyledMenuItem onClick={handleOnClick} active={active}>
      <img src={icon} alt={alt} />
      {!collapsed && (
        <Text size="3" color="violet">
          {title}
        </Text>
      )}
    </StyledMenuItem>
  );
};

const menuItems = [
  {
    id: "dashboard",
    icon: dashboardIcon,
    alt: "dashboard icon",
    title: "Dashboard",
    collapsed: false,
    path: "/dashboard",
  },
  {
    id: "flying-eye",
    icon: cameraIcon,
    alt: "camera icon",
    title: "Flying eye",
    collapsed: false,
    path: "/dashboard/camera",
  },
  {
    id: "site-insights",
    icon: insightIcon,
    alt: "insight icon",
    title: "Site Insights",
    collapsed: false,
    path: "/dashboard/insights",
  },
  {
    id: "eyal-safety",
    icon: eyalIcon,
    alt: "Eyal icon",
    title: "Eyal Safety",
    collapsed: false,
    path: "/dashboard",
  },
  {
    id: "progress-tracking",
    icon: trackingIcon,
    alt: "Tracking icon",
    title: "Progress Tracking",
    collapsed: false,
    path: "/dashboard",
  },
  {
    id: "documentation",
    icon: documentationIcon,
    alt: "Documentation icon",
    title: "Documentation",
    collapsed: false,
    path: "/dashboard",
  },
];

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenuId, setActiveMenuId] = useState(null);
  return (
    <Box>
      <Nav>
        <Flex align="center">
          <Flex justify="start" align="center" gapX="15px">
            <img src={logo} alt="logo" height="45px" width="45px" />
            <Text size="5" style={{ color: "white" }} weight="bold">
              ATOM
            </Text>
          </Flex>
          {/* <Box ml="90px">
            <Select.Root size="3" name="role">
              <Select.Trigger
                placeholder="Name of the project"
                required
                style={{ background: "transparent" }}
              />
              <Select.Content>
                <Select.Item value="kilu">Kilu Inc</Select.Item>
              </Select.Content>
            </Select.Root>
          </Box> */}
          <Flex align="center" ml="auto" gapX="18px">
            <GoGear
              size="20px"
              color="white"
              style={{ fontWeight: "bolder" }}
            />
            <Avatar radius="full" size="4" src={sampleImageUrl} />
          </Flex>
        </Flex>
      </Nav>
      <Box>
        <SideMenu style={{ width: collapsed ? "80px" : "240px" }}>
          {menuItems.map((item) => (
            <MenuItem
              id={item.id}
              key={item.id}
              icon={item.icon}
              alt={item.alt}
              collapsed={collapsed}
              title={item.title}
              path={item.path}
              active={item.id === activeMenuId}
              onClick={(id) => setActiveMenuId(id)}
            />
          ))}
          <MenuControl onClick={() => setCollapsed((col) => (col = !col))}>
            {collapsed ? (
              <MdOutlineKeyboardDoubleArrowRight
                fontSize="30"
                color={colors.active}
              />
            ) : (
              <MdKeyboardDoubleArrowLeft fontSize="30" color={colors.active} />
            )}
            {!collapsed && (
              <Text size="3" color="violet">
                Collapse Menu
              </Text>
            )}
          </MenuControl>
        </SideMenu>
        <DashboardContent style={{ paddingLeft: collapsed ? "80px" : "240px" }}>
          <Outlet />
        </DashboardContent>
      </Box>
    </Box>
  );
};

export default Dashboard;
